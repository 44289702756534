import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"

import DesignCirclePartImage from "../images/mask@3x.png"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"
import DesignRectangle from "../images/greener-square.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  mainDescription2,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeDescription2,
  challengeItems,
  challengeImage,
  solutionDescription,
  solutionDescription2,
  solutionImage,
  resultTitle,
  resultDescription,
  resultDescription2,
  resultDescription3,
  resultImage,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative lg:flex container mx-auto py-24 pb-2 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180 hidden md:block top-8 right-164"
        />
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-48 right-8 bottom-20 hidden md:block"
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-5xl sm:text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="font-semibold text-lg text-gray-700 lg:pr-12">
            {mainDescription2}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-7/8 left-8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="bg-gray-300">
        <div className="realative container mx-auto py-8 px-16 md:px-10 lg:px-32">
          <img
            src={DesignRectangle}
            alt=""
            className="relative -left-10 top-9"
          />
          <h2 className="font-semibold text-3xl">{challengeTitle}</h2>
          <div className="md:flex md:flex-row pb-2">
            <div className="relative container md:w-3/5 md:pr-30 mb-8 md:mb-0 items-center">
              <img
                src={challengeImage.childImageSharp.fixed.src}
                alt=""
                className="absolute -bottom-10 right-24 hidden md:block"
              />
            </div>
            <div className="md:w-2/3 text-gray-500 md:pl-2 md:py-8">
              <div>
                <p>{challengeDescription}</p>
                <br />
                <ul className="text-gray-500 list-default leading-7 pl-8">
                  {challengeItems.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
                <br />
                <p className="md:pb-6">{challengeDescription2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK*/}
      <div className="container mx-auto py-12 pt-16 pb-0 px-16 md:px-10 lg:px-32">
        <div className="md:flex flex-row">
          <div className="flex flex-1">
            <div className="md:w-1/2 md:py-12">
              <img
                src={DesignRectangle}
                alt=""
                className="relative -left-10 top-5"
              />
              <p className="mb-10 text-gray-500">{solutionDescription}</p>
              <p className="mb-10 text-gray-500 md:pb-8 pb-4">
                {solutionDescription2}
              </p>
            </div>

            <div className="relative md:w-2/3 hidden md:block">
              <img
                src={solutionImage.childImageSharp.fixed.src}
                alt=""
                className="md:absolute -right-16 top-10"
              />
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-24 transform -rotate-90 -top-6 -right-20"
              />
            </div>
          </div>
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="bg-gray-300">
        <div className="container mx-auto pt-5 px-16 md:px-10 lg:px-32">
          <div className="md:flex flex-row">
            <div className="relative md:w-1/2 hidden md:block">
              <img
                src={resultImage.childImageSharp.fixed.src}
                alt=""
                className="md:absolute right-12 top-20"
              />
            </div>
            <div className="md:w-1/2 py-16 relative pr-2">
              <h1 className="mb-8 font-semibold text-lg text-gray-700 md:pr-12">
                {resultTitle}
              </h1>
              <p className="mb-8 text-gray-500 md:pr-12">{resultDescription}</p>
              <p className="mb-8 text-gray-500 md:pr-12">
                {resultDescription2}
              </p>
              <p className="mb-8 pb-4 text-gray-500 md:pr-12">
                {resultDescription3}
              </p>
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-24 transform bottom-8 -right-4"
              />
            </div>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <AppDevThumbnail className="md:w-1/3" />
          <SoftDevThumbnail className="md:w-1/3" />
          <UiUxThumbnail className="md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const InstanticCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default InstanticCaseStudy

export const pageQuery = graphql`
  query instanticCaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        mainDescription2
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeDescription2
        challengeItems
        challengeImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionDescription
        solutionDescription2
        solutionImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultTitle
        resultDescription
        resultDescription2
        resultDescription3
        resultImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
